<template>
    <div class="elmain">
        <el-form ref="form" :model="params" label-width="100px">
            <el-row :gutter="20">
                <el-col :span="6">
                    <el-form-item label="客户名称">
                        <el-select filterable allow-create clearable v-model="params.customer_name" placeholder="请选择"
                                   style="width: 100%;">
                            <el-option v-for="item in customerlist" :key="item.id" :label="item.name"
                                       :value="item.name">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="关键字搜索">
                        <el-input placeholder="请输入零件名称、零件编号、图纸型号、订单名称、订单编号" v-model="params.keyword"
                                  autocomplete="off"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-button type="primary" @click="cx">查询</el-button>
                    <el-button type="success" @click="add">新增</el-button>
                </el-col>
            </el-row>
        </el-form>
        <!-- 修改框 -->
        <el-dialog title="零件库存信息" :visible.sync="dialogFormVisible">
            <el-form :model="dialogData">
                <el-form-item label="客户名称" :label-width="formLabelWidth">
                    <el-select filterable allow-create v-model="dialogData.customer_name" placeholder="请选择"
                               style="width: 100%;">
                        <el-option v-for="item in customerlist" :key="item.id" :label="item.name" :value="item.name">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="订单编号" :label-width="formLabelWidth">
                    <el-input v-model="dialogData.order_no" autocomplete="off"
                              :disabled="orderInfoDisabled"></el-input>
                </el-form-item>
                <el-form-item label="订单名称" :label-width="formLabelWidth">
                    <el-input v-model="dialogData.order_name" autocomplete="off"
                              :disabled="orderInfoDisabled"></el-input>
                </el-form-item>
                <el-form-item label="零件编号" :label-width="formLabelWidth">
                    <el-input v-model="dialogData.order_item_no" autocomplete="off"
                              :disabled="orderInfoDisabled"></el-input>
                </el-form-item>
                <el-form-item label="图纸型号" :label-width="formLabelWidth">
                    <el-input v-model="dialogData.drawing_model" autocomplete="off"
                              :disabled="orderInfoDisabled"></el-input>
                </el-form-item>
                <el-form-item label="零件名称" :label-width="formLabelWidth">
                    <el-input v-model="dialogData.order_item_name" autocomplete="off"
                              :disabled="orderInfoDisabled"></el-input>
                </el-form-item>
                <el-form-item label="安全库存" :label-width="formLabelWidth">
                    <el-input-number v-model.number="dialogData.safe_stock" autocomplete="off"></el-input-number>
                </el-form-item>
                <el-form-item label="剩余库存" :label-width="formLabelWidth">
                    <el-input-number v-model.number="dialogData.stock_num" autocomplete="off"></el-input-number>
                </el-form-item>
                <el-form-item label="备注" :label-width="formLabelWidth">
                    <el-input v-model="dialogData.remark" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </div>
        </el-dialog>
        <!-- biao -->
        <div class="" style="width: 100%;">
            <el-table :data="list" border style=" width: 100%;margin-top: 20px;">
                <el-table-column prop="customer_name" label="客户"></el-table-column>
                <el-table-column prop="order_no" label="订单编号"></el-table-column>
                <el-table-column prop="order_name" label="订单名称"></el-table-column>
                <el-table-column prop="order_item_no" label="零件编号"></el-table-column>
                <el-table-column prop="drawing_model" label="图纸型号"></el-table-column>
                <el-table-column prop="order_item_name" label="零件名称"></el-table-column>
                <el-table-column prop="safe_stock" label="安全库存"></el-table-column>
                <el-table-column prop="stock_num" label="库存数量"></el-table-column>
                <el-table-column prop="remark" label="备注"></el-table-column>
                <el-table-column label="进销记录" width="100" align="center">
                    <template slot-scope="scope">
                        <span class="ly" @click="linyong(scope.row)" type="warning" size="small">点击查看</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="280" fixed="right">
                    <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row)" type="primary" size="small">编辑</el-button>
                        <el-button type="success" @click="handleFormShow(2, scope.row)" size="small">入库</el-button>
                        <el-button @click="handleFormShow(1, scope.row)" type="warning" size="small">出库</el-button>
                        <el-button @click="deleteg(scope.row)" type="danger" size="small">删除</el-button>

                    </template>
                </el-table-column>
            </el-table>
            <div class="block" style="    margin-top: 10px;">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                               :current-page.sync="page" :page-sizes="[5,10, 50, 100]" :page-size="limit"
                               layout="sizes, prev, pager, next" :total="total">
                </el-pagination>

            </div>
        </div>
        <el-dialog title="领用列表" :visible.sync="islylist">
            <el-table :data="lylist" border style="width: 100%;margin-top: 20px;">
                <el-table-column prop="order_item_stock.order_item_name" label="零件名称" min-width="10%"></el-table-column>
                <el-table-column prop="staff_name" label="操作人" min-width="10%"></el-table-column>
                <el-table-column prop="created_at" label="操作时间" min-width="10%"></el-table-column>
                <el-table-column prop="created_at" label="类型" min-width="10%">
                    <template slot-scope="scope">
                        {{ scope.row.num >= 0 ? '出库' : '入库' }}
                    </template>
                </el-table-column>
                <el-table-column prop="receive_nums" label="操作数量" min-width="10%">
                    <template slot-scope="scope">
                        {{ Math.abs(scope.row.num) }}
                    </template>
                </el-table-column>
            </el-table>
            <!-- <el-pagination @current-change="handleCurrentChange2" :current-page.sync="lyquer.page" :page-size="lyquer.limit" layout="sizes, prev, pager, next" :total="lytotal">
            </el-pagination> -->
            <el-pagination @current-change="handleCurrentChange2" :page-size="lyquer.limit" layout="prev, pager, next"
                           :total="lytotal">
            </el-pagination>
        </el-dialog>
        <el-dialog :title="handleTitle" :visible.sync="handleVisiable">
            <el-form ref="handleForm" :model="handleForm" :label-width="formLabelWidth">
                <el-form-item label="操作人员" :label-width="formLabelWidth">
                    <el-select
                        v-model="handleForm.staff_id"
                        filterable
                        remote
                        reserve-keyword
                        :remote-method="getStaffListData"
                        :loading="staffListLoading"
                        default-first-option
                        placeholder="请选择">
                        <el-option
                            v-for="item in staffList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="数量" :label-width="formLabelWidth">
                    <el-input-number v-model.number="handleForm.num" autocomplete="off"></el-input-number>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="handleVisiable = false">取 消</el-button>
                <el-button type="primary" @click="handleFormSubmit">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

import {add, getList, deletes, edit, logs, receive} from "@/service/orderItemStock";
import {getcustomer, getstaff} from '@/service/api';

export default {
    data() {
        return {
            type_id: '',
            formLabelWidth: '120px',
            devList: [],
            typelist: [],
            types: [],
            page: 1,
            limit: 10,
            list: [],
            total: 0,
            dialogFormVisible: false,
            dialogData: {
                id: '',
                order_no: '',
                order_name: '',
                safe_stock: 0,
                stock_num: 0,
                order_item_name: '',
                order_item_no: '',
                drawing_model: '',
                remark: '',
                customer_name: ''
            },
            dialogFormVisible2: false,
            type: 'add',
            islylist: false,
            lylist: [],
            lyquer: {
                page: 1,
                limit: 10
            },
            lyid: '',
            lytotal: 0,
            not_stock: '',
            params: {
                keyword: '',
                customer_name: ''
            },
            customerlist: [],
            staffList: [],
            orderInfoDisabled: false,
            handleTitle: '零件出库',
            handleVisiable: false,
            handleForm: {
                item_stock_id: '',
                num: '',
                staff_id: '',
                type: ''
            },
            staffListLoading: false
        };
    },
    methods: {
        handleFormShow(type, row) {
            this.handleForm = {
                item_stock_id: row.id,
                staff_id: '',
                num: '',
                type: type
            };
            this.handleVisiable = true;
            if (type === 1) {
                this.handleTitle = "零件出库";
            } else {
                this.handleTitle = "零件入库";
            }
        },
        cx() {
            this.page = 1;
            this.getlist();
        },
        getcustomer() {
            getcustomer({limit: 100, page: 1, name: ''}).then(res => {
                this.customerlist = res.data.list;
            });
        },
        getStaffListData(name) {
            let data = {
                limit: 30,
                page: 1,
                name: name,
                company_group_id: ''
            };
            this.staffListLoading = true;
            getstaff(data).then(res => {
                this.staffListLoading = false;
                this.staffList = res.data.list;
                // this.total = res.data.total;
            });
        },
        all() {
            this.not_stock = '';
            this.getlist();
        },
        getkc() {
            this.not_stock = 1;
            this.getlist();
        },
        linyong(row) {
            console.log(row);
            this.islylist = true;
            this.lyquer = {
                page: 1,
                limit: 10
            };
            this.lyid = row.id;
            this.getlogs();
        },
        getlogs() {
            let data = this.lyquer;
            data.item_stock_id = this.lyid;
            logs(data).then(res => {
                console.log(res);
                this.lylist = res.data.list;
                this.lytotal = res.data.total;
            });
        },
        tab_type_id(type) {
            this.type_id = type;
            this.getlist();
        },
        add() {
            this.orderInfoDisabled = false;
            this.dialogFormVisible = true;
            this.type = "add";
            this.dialogData = {
                id: '',
                customer_name: '',
                order_no: '',
                order_name: '',
                safe_stock: 0,
                stock_num: 0,
                order_item_name: '',
                order_item_no: '',
                drawing_model: '',
                remark: ''
            };

        },
        handleClick(row) {
            console.log(row);
            this.dialogFormVisible = true;
            this.type = "put";
            this.dialogData = {...row};
            if (this.dialogData.order_item_id) {
                this.orderInfoDisabled = true;
            } else {
                this.orderInfoDisabled = false;
            }
        },
        deleteg(i) {
            console.log(i);
            let that = this;
            this.$confirm('此操作将永久删除该零件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id: i.id
                };
                deletes(data).then(res => {
                    if (res.code === 200) {
                        that.getlist();
                        that.$message({
                            type: 'success',
                            message: '删除成功!'
                        });

                    }

                });

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });

        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.limit = val;
            this.getlist();
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.lyquer.page = val;
            this.getlist();
        },
        handleCurrentChange2(val) {
            console.log(`当前页: ${val}`);
            this.page = val;
            this.getlogs();
        },
        submit() {
            if (this.dialogData.order_item_name === '') {
                this.$message.error('请输入零件名称');
                return;
            }
            if (this.dialogData.safe_stock === 0) {
                this.$message.error('请输入安全库存');
                return;
            }
            this.dialogData.safe_stock = Number(this.dialogData.safe_stock);
            if (this.type === 'add') {
                add(this.dialogData).then(res => {
                    if (res.code === 200) {
                        this.$message({
                            message: '添加成功',
                            type: 'success'
                        });
                        this.getlist();
                        this.dialogFormVisible = false;
                    }

                });
            }
            if (this.type === 'put') {
                console.log(this.dialogData);
                edit(this.dialogData).then(res => {
                    if (res.code === 200) {
                        this.$message({
                            message: '修改成功',
                            type: 'success'
                        });
                        this.getlist();
                        this.dialogFormVisible = false;
                    }
                });
            }

        },
        getlist() {
            let data = {limit: this.limit, page: this.page, not_stock: this.not_stock};
            data = Object.assign(data, this.params);
            getList(data).then(res => {
                console.log(res);
                this.list = res.data.list;
                this.total = res.data.total;
                this.types = res.data.types;
            });
        },
        handleFormSubmit() {
            if (!this.handleForm.num) {
                this.$message.error('请输入操作数量');
                return;
            }
            if (this.handleForm.type === 2) {
                this.handleForm.num = 0 - this.handleForm.num;
            }
            receive(this.handleForm).then(res => {
                this.$message({
                   message: res.message || '操作成功！',
                   type: 'success'
                });
                this.getlist();
                this.handleVisiable = false;
            });
        }
    },
    mounted() {
        this.getlist();
        this.getcustomer();
    }
};
</script>
<style scoped lang="scss">
.one {
    &-time {
        text-align: center;
    }
}

.top {
    padding: 20px 40px;
    // height: 80px;
    background: #ffffff;
    // display: flex;
    // align-items: center;

    &-item {
        display: flex;
        align-items: center;
        margin-right: 50px;

        .title {
            margin-right: 10px;
            font-size: 18px;
            font-weight: 700;
        }

        .data {
            margin: 0 30px;
            padding: 10px 0;

            border-radius: 30px;
            width: 80px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.hover {
                background: rgb(100, 181, 246);
            }
        }
    }
}

.elmain {
    padding: 50px;
}

@media screen and (max-width: 750px) {
    .top {
        padding: 10px;
    }
    .top-item {
        flex: 1;
    }
    .top-item .data {
        margin: 0 10px;
        width: auto;
        font-size: 12px;
        padding: 5px;
    }
}

.one-time {
    font-size: 16px;
    line-height: 24px;
}

.devList {
    width: 100%;
    margin-top: 20px;
}

.devListbox {
    display: inline-block;
    font-size: 14px;
    padding: 8px 10px;
    background: #209e91;
    color: white;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
    margin-top: 10px;
}

.active {
    background: #e6a23c;
}

.ly {
    cursor: pointer;
    text-decoration: underline;
}

.make {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 99999999;
    padding-top: 20%;
    text-align: center;

    .title {
        color: white;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 30px;
    }

    #qrcode {
        width: 200px;
        text-align: center;
        margin: 0 auto;

        img {
            width: 100%;
        }
    }

    .butbox {
        margin-top: 20px;
    }
}
</style>
