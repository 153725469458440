import request from '@/service/lib/request';
import {setUrlQuery} from "@/utlis/url";

const module = 'orderItemStock';

// 库存
export function add(data) {
    return request({
        url: module,
        method: 'post',
        data
    });

}

export function getList(data) {
    return request({
        url: setUrlQuery({
            url: module,
            query: data
        }),
        method: 'get'
    });

}

export function deletes(data) {
    return request({
        url: module + '/' + data.id,
        method: 'delete'
    });

}

export function edit(data) {
    return request({
        url: module + '/' + data.id,
        method: 'put',
        data
    });
}

export function logs(data) {
    return request({
        url: setUrlQuery({
            url: module + '/logs',
            query: data
        }),
        method: 'get'
    });

}

// 库存
export function receive(data) {
    return request({
        url: module + '/receive',
        method: 'post',
        data
    });

}
